const ServiceLink = () => {

  return (
    <div className="textCenter">
      <span style={{fontSize: "11px"}}>Powered by <a className="link" href="https://tools.onout.org/dao" target="_blank" rel="noreferrer">OnOut - no-code tool to create DAO</a></span>
    </div>
  )

};

export default ServiceLink;